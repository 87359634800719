<template>
  <p>New Budget works!</p>
</template>

<script lang="ts">
import { useStore } from "@/services/store";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NewBudget",
  components: {},
  setup() {
    const store = useStore();

    return {};
  }
});
</script>

<style lang="scss" scoped></style>
